import { Box, FormControl, Grid, Drawer, Typography, Icon, LinearProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { IEndereco } from '../../../../commons/genericTypes';
import { ButtonStato, IconDrawer, TextFieldStato } from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { useEnderecoActions } from '../../../../states/crm/contatoAuxiliares/enderecos/endereco.actions';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { GridItemCadastroBtnSalvar } from '..';
import ButtonAdd from '../ButtonAdd';

const empty: IEndereco = {
  id: 0,
  idContato: 0,
  idTipo: 3,
  cep: '',
  endereco: '',
  numero: '',
  complemento: '',
  bairro: '',
  cidade: '',
  estado: '',
  pais: '',
  principal: '',
  ativo: '',
};

export interface IParams {
  id?: string;
}

const EnderecoComponent: React.FC = () => {
  const { id } = useParams<IParams>();
  const auth = useRecoilValue(authAtom);
  const enderecoActions = useEnderecoActions();

  const [open, setOpen] = useState<boolean>(false);
  const [cepValido, setCepValido] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [loadingCep, setLoadingCep] = useState<boolean>(false);
  const [enderecos, setEnderecos] = useState<IEndereco[]>([]);
  const [endereco, setEndereco] = useState<IEndereco>(empty);

  const columns: GridColDef[] = [
    {
      field: 'cep',
      headerName: 'Cep',
      width: 100,
    },
    {
      field: 'endereco',
      headerName: 'Endereço',
      width: 150,
    },
    {
      field: 'numero',
      headerName: 'Número',
      width: 90,
    },
    {
      field: 'complemento',
      headerName: 'Complemento',
      width: 150,
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      width: 150,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 100,
    },
    {
      field: 'principal',
      headerName: 'Principal',
      width: 100,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEndereco;
        return (
          <>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleEditar(enderecos.find((f) => f.id === obj.id!)!)}
            >
              {' '}
              <EditIcon />{' '}
            </Icon>
            <Icon sx={{ cursor: 'pointer' }} onClick={() => handleApagar(obj.id!)}>
              {' '}
              <DeleteIcon />{' '}
            </Icon>
          </>
        );
      },
    },
  ];

  const handleEditar = (endereco: IEndereco) => {
    setEndereco(endereco);
    setOpen(true);
  };

  const getUrlViaCep = (cep: string) => {
    let Httpreq = new XMLHttpRequest();
    Httpreq.open('GET', 'https://viacep.com.br/ws/' + cep.replace('-', '') + '/json/', false);
    Httpreq.send(null);
    return Httpreq.responseText;
  };

  useEffect(() => {
    enderecoActions.list(false, `?contatoId=${id}`).then((data) => setEnderecos(data));

    setLoading(false);
  }, []);

  const openDrawerEndereco = (result: any) => {
    setEndereco(empty);
    setOpen(result);
  };

  const handleApagar = (id: any) => {
    enderecoActions.delete(id).then(() => {
      const sub = enderecos.filter((f) => f.id !== id);
      setEnderecos(sub);
      toast.success('Endereço excluído');
    });
  };

  const submitEndereco = () => {
    adicionarNovo();
  };

  const adicionarNovo = async () => {
    setLoadingSave(true);
    endereco.idTipo = 3;
    if ((!endereco?.idContato || endereco?.idContato <= 0) && Number(id) > 0) {
      endereco!.idContato = Number(id);
    }

    if (endereco.id && endereco.id > 0) {
      const response = await enderecoActions.update(Number(endereco.id), endereco!);
      const newList = enderecos.filter((f) => f.id !== response!.id);
      setEnderecos(newList.concat(response!));
    } else {
      const response = await enderecoActions.create(endereco!);
      const newList = enderecos.concat(response!);
      setEnderecos(newList);
    }

    setEndereco(emptyEndereco(''));
    toast.success('Endereço salvo');
    openDrawerEndereco(false);
    setLoadingSave(false);
  };

  const handleGetCep = (event: any) => {
    if (event.target.value.length <= 9) {
      let input = event.target;
      input.value = getCepMask(input.value);
      setEndereco({ ...endereco, cep: input.value });
    }
    if (event.target.value.length < 9) {
      if (cepValido === true) {
        setEndereco(emptyEndereco(event.target.value));
        setCepValido(false);
      }
      return;
    }

    setLoadingCep(true);
    const enderecoCep = JSON.parse(getUrlViaCep(event.target.value));
    if (enderecoCep !== null && !enderecoCep.erro) {
      setCepValido(true);
      setEndereco({
        id: endereco.id,
        cep: event.target.value,
        bairro: enderecoCep.bairro,
        cidade: enderecoCep.localidade,
        endereco: enderecoCep.logradouro,
        estado: enderecoCep.uf,
      });
    } else {
      setCepValido(false);
      setEndereco(emptyEndereco(event.target.value));
    }
    setLoadingCep(false);
  };

  const emptyEndereco = (cep: string) => {
    return {
      id: endereco.id,
      idContato: Number(id),
      idTipo: 3,
      cep: cep,
      bairro: '',
      cidade: '',
      endereco: '',
      estado: '',
    };
  };

  const getCepMask = (value: any) => {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{5})(\d)/, '$1-$2');
    return value;
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id}_${row?.cep}`}
              rows={enderecos ?? []}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <ButtonAdd onClick={() => openDrawerEndereco(true)} />

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerEndereco(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerEndereco(false)}>close</IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant="h5">{'Adicionar Endereço na Lista'}</Typography>
                </Grid>
              </Grid>

              <FormControl
                id="cep"
                required
                fullWidth
                variant="outlined"
                sx={{ marginTop: '20px' }}
              >
                <TextFieldStato
                  type="text"
                  label="Cep"
                  value={endereco?.cep}
                  onChange={(e) => handleGetCep(e)}
                  inputProps={{ maxLength: 9 }}
                />
              </FormControl>

              {loadingCep && <LinearProgress />}

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'EnderecoInput'}
                  type="Endereco"
                  label="Endereço"
                  value={endereco?.endereco}
                  disabled={cepValido}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ ...endereco, endereco: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'EnderecoInput'}
                  type="numero"
                  label="Número"
                  value={endereco?.numero}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ ...endereco, numero: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'EnderecoInput'}
                  type="complemento"
                  label="Complemento"
                  value={endereco?.complemento}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEndereco({
                      ...endereco,
                      complemento: event.target.value,
                    });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'EnderecoInput'}
                  type="bairro"
                  label="Bairro"
                  disabled={cepValido}
                  value={endereco?.bairro}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ ...endereco, bairro: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'EnderecoInput'}
                  type="cidade"
                  label="Cidade"
                  disabled={cepValido}
                  value={endereco?.cidade}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ ...endereco, cidade: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'EnderecoInput'}
                  type="estado"
                  label="Estado"
                  disabled={cepValido}
                  value={endereco?.estado}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ ...endereco, estado: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'EnderecoInput'}
                  type="pais"
                  label="País"
                  value={endereco?.pais}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEndereco({ ...endereco, pais: event.target.value });
                  }}
                />
              </FormControl>

              {loadingSave && <LinearProgress />}

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant="contained" onClick={submitEndereco}>
                  Adicionar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
};

export default EnderecoComponent;
