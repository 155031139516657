import { styled } from 'styled-components';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Alert,
  Drawer,
  Typography,
  Icon,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridEventListener, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { IGraduacao } from '../../../../commons/genericTypes';
import {
  ButtonStato,
  GridContainer,
  GridContainerCenter,
  IconDrawer,
  IconTituloStato,
  SelectStato,
  TextFieldStato,
} from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import { useGraduacaoActions } from '../../../../states/crm/contatoAuxiliares/graduacoes/graduacao.actions';
import ButtonAdd from '../ButtonAdd';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { IParams } from '../Idioma';

interface Props {
  novoUsuario: boolean;
}

const GraduacaoComponent: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const { id } = useParams<IParams>();
  const auth = useRecoilValue(authAtom);
  const graduacaoActions = useGraduacaoActions();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [mes, setMes] = useState<string>('');
  const [ano, setAno] = useState<string>('');
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [graduacoes, setGraduacaos] = useState<IGraduacao[]>([]);
  const [listGraduacao, setListGraduacao] = useState<any[]>([
    { id: 1, nome: 'Graduação, Pós, MBA e Especializações' },
    { id: 2, nome: 'Outros cursos ou seminários/congressos' },
  ]);
  const [graduacao, setGraduacao] = useState<IGraduacao>();
  const [selectedGraduacaos, setSelectedGraduacaos] = useState<IGraduacao[] | undefined>(undefined);

  const columns: GridColDef[] = [
    {
      field: 'tipoFormacao',
      headerName: 'Tipo Formação',
      width: 250,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IGraduacao;
        return listGraduacao?.find((f) => f.id === obj.tipo)?.nome;
      },
    },
    {
      field: 'nome',
      headerName: 'Curso',
      width: 150,
    },
    {
      field: 'programa',
      headerName: 'Instituição',
      width: 120,
    },
    {
      field: 'cursando',
      headerName: 'Cursando',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IGraduacao;
        return (
          <Checkbox
            disabled={true}
            checked={obj.graduado}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        );
      },
    },
    {
      field: 'graduacaoEm',
      headerName: 'Graduação Em',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IGraduacao;
        return `${obj.mesGraduacao}/${obj.anoGraduacao}`;
      },
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 150,
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IGraduacao;
        return (
          <>
            <Icon sx={{ cursor: 'pointer' }} onClick={() => handleEditar(obj)}>
              {' '}
              <EditIcon />{' '}
            </Icon>
            <Icon sx={{ cursor: 'pointer' }} onClick={() => handleApagar(obj.id!)}>
              {' '}
              <DeleteIcon />{' '}
            </Icon>
          </>
        );
      },
    },
  ];

  const handleEditar = (graduacao: IGraduacao) => {
    setIdAtualizar(graduacao.id);
    setGraduacao(graduacao);
    setOpen(true);
  };

  useEffect(() => {
    if (!id) return;

    graduacaoActions
      .list(false, `?contatoId=${id}`)
      .then((resp: IGraduacao[]) => setGraduacaos(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, [id]);

  const HandleSetFormcao = (result: any) => {
    setGraduacao({ ...graduacao, tipo: result.target.value });
  };

  const openDrawerGraduacao = (result: any) => {
    setGraduacao(undefined);
    setOpen(result);
  };

  const handleApagar = (id: any) => {
    graduacaoActions
      .delete(id)
      .then((data) => {
        const sub = graduacoes.filter((f) => f.id !== id);
        setGraduacaos(sub);
        toast.success('Item excluído');
      })
      .catch((erro) => {});
  };

  const adicionarNaLista = () => {
    if (!graduacao?.tipo) {
      toast.warn('Selecione o tipo de formação');
      return;
    }
    setLoading(true);

    let idContato = 0;
    if (Number(id) > 0) graduacao!.IdContato = Number(id);

    if (graduacao?.id && graduacao?.id > 0)
      graduacaoActions.update(graduacao!.id, graduacao).then((data: IGraduacao) => {
        const newList = graduacoes.filter((g) => g.id !== data.id);
        setGraduacaos([...newList, data]);
        openDrawerGraduacao(false);
        setLoading(false);
        toast.success('Item editado');
      });
    else
      graduacaoActions.create(graduacao!).then((data: IGraduacao) => {
        const newList = graduacoes.concat(data!);
        setGraduacaos(newList);
        openDrawerGraduacao(false);
        setLoading(false);
        toast.success('Item adicionado');
      });
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id}_${Math.floor(Math.random() * 20)}`}
              rows={graduacoes ?? []}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <ButtonAdd onClick={() => openDrawerGraduacao(true)} />

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerGraduacao(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerGraduacao(false)}>close</IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant="h5">{'Adicionar Graduação na Lista'}</Typography>
                </Grid>
              </Grid>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <InputLabel id="demo-simple-select-label">Tipo Formação</InputLabel>
                <SelectStato
                  value={graduacao?.tipo}
                  onChange={HandleSetFormcao}
                  required
                  label="Tipo Formação"
                >
                  {listGraduacao &&
                    listGraduacao!.map((graduacaoMap, index: number) => (
                      <MenuItem value={graduacaoMap.id} key={index}>
                        {graduacaoMap.nome}
                      </MenuItem>
                    ))}
                </SelectStato>
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'GraduacaoInput'}
                  type="text"
                  label="Curso"
                  value={graduacao?.nome}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGraduacao({ ...graduacao, nome: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  id={'GraduacaoInput'}
                  type="text"
                  label="Instituição"
                  value={graduacao?.programa}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGraduacao({ ...graduacao, programa: event.target.value });
                  }}
                />
              </FormControl>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setGraduacao({ ...graduacao, graduado: event.target.checked });
                      }}
                      checked={graduacao?.graduado}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Cursando"
                />
              </FormControl>

              <InputLabel sx={{ marginTop: '20px' }}>Graduação em:</InputLabel>
              <Grid item xs={12} md={12} style={{ display: 'flex' }}>
                <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                  <FormControl
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ marginTop: '10px', marginRight: '10px' }}
                  >
                    <TextFieldStato
                      id={'GraduacaoInput'}
                      type="number"
                      label="Mês"
                      value={graduacao?.mesGraduacao}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setGraduacao({
                          ...graduacao,
                          mesGraduacao: event.target.value,
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={6} style={{ display: 'flex' }}>
                  <FormControl required fullWidth variant="outlined" sx={{ marginTop: '10px' }}>
                    <TextFieldStato
                      id={'GraduacaoInput'}
                      type="number"
                      label="Ano"
                      value={graduacao?.anoGraduacao}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setGraduacao({
                          ...graduacao,
                          anoGraduacao: Number(event.target.value),
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <TextFieldStato
                  sx={{ height: 90 }}
                  id={'GraduacaoInput'}
                  type="text"
                  label="Descrição do Curso ou Certificações"
                  value={graduacao?.descricao}
                  multiline
                  maxRows={4}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setGraduacao({ ...graduacao, descricao: event.target.value });
                  }}
                />
              </FormControl>

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant="contained" onClick={adicionarNaLista}>
                  Adicionar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default GraduacaoComponent;
