import { styled } from 'styled-components';
import {
  Box,
  FormControl,
  Grid,
  Drawer,
  Typography,
  Icon,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridEventListener, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useRecoilValue } from 'recoil';
import { ICv, IIdioma } from '../../../../commons/genericTypes';
import { ButtonStato, IconDrawer, SelectStato, TextFieldStato } from '../../../../commons/styleds';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import InputArquivo from '../../../../components/InputArquivo/InputArquivo';
import { useCvActions } from '../../../../states/crm/contatoAuxiliares/cvs/cv.actions';
import { useIdiomaConfigActions } from '../../../../states/config/ConfiguracaoSistema/idiomas/idioma.actions';
import ButtonAdd from '../ButtonAdd';
import { useParams } from 'react-router-dom';
import { IParams } from '../Idioma';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  novoUsuario: boolean;
}

const CurriculoComponent: React.FC<Props> = (props: Props) => {
  const { novoUsuario } = props;
  const { id } = useParams<IParams>();
  const auth = useRecoilValue(authAtom);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [cvs, setCVs] = useState<ICv[]>([]);
  const [cv, setCV] = useState<ICv>();
  const [selectedCVs, setSelectedCVs] = useState<ICv[] | undefined>(undefined);
  const [idioma, setIdioma] = useState<IIdioma>();
  const [editAcao, setEditAcao] = useState<boolean>(false);
  const [acaoEmEdicao, setAcaoEmEdicao] = useState<ICv>({});
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [acaoDeleteId, setAcaoDeleteId] = useState<number>(0);
  const [nomeArquivo, setNomeArquivo] = useState('');
  const [arquivoUrl, setArquivoUrl] = useState('');
  const [idiomasSelect, setIdiomasSelect] = useState<IIdioma[]>([]);
  const [idiomaSelected, setIdiomaSelected] = useState<number>();
  const idiomaConfigActions = useIdiomaConfigActions();
  const cvActions = useCvActions();

  const columns: GridColDef[] = [
    {
      field: 'nomeCv',
      headerName: 'Nome',
      width: 190,
    },
    {
      field: 'idioma',
      headerName: 'Idioma',
      width: 150,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ICv;
        return idiomasSelect?.find((f) => f.id === obj.idIdioma)?.nome;
      },
    },
    {
      field: 'acao',
      headerName: 'Ação',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as ICv;
        return (
          <>
            <Icon
              sx={{ cursor: 'pointer' }}
              onClick={() => handleEditar(cvs.find((f) => f.id === obj.id!)!)}
            >
              {' '}
              <EditIcon />{' '}
            </Icon>
            <Icon sx={{ cursor: 'pointer' }} onClick={() => handleApagar(obj.id!)}>
              {' '}
              <DeleteIcon />{' '}
            </Icon>
          </>
        );
      },
    },
  ];

  const handleEditar = (cv: ICv) => {
    setIdAtualizar(cv.id!);
    setIdioma(idiomasSelect.find((f) => f.id == cv.idIdioma));
    setIdiomaSelected(cv.idIdioma);
    setArquivoUrl(cv.anexo);
    setNomeArquivo(cv.nomeCv ?? '');
    setCV(cv);
    setOpen(true);
  };

  useEffect(() => {
    idiomaConfigActions
      .list()
      .then((resp: IIdioma[]) => setIdiomasSelect(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  useEffect(() => {
    if (!id) return;

    cvActions
      .list(false, `?contatoId=${id}`)
      .then((resp: ICv[]) => setCVs(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, [id]);

  const openDrawerCV = (result: any) => {
    setCV(undefined);
    setIdioma(undefined);
    setIdiomaSelected(undefined);
    setArquivoUrl('');
    setNomeArquivo('');
    setOpen(result);
  };

  const handleApagar = (id: any) => {
    cvActions.delete(id).then(() => {
      const sub = cvs.filter((f) => f.id !== id);
      setCVs(sub);
      toast.success('CV excluído');
    });
  };

  const adicionarNaLista = () => {
    if (Number(id) <= 0) {
      toast.warn('Selecione um contato');
      return;
    }

    cv!.nome = nomeArquivo;
    cv!.idioma = idioma?.nome;
    cv!.idIdioma = idioma?.id;
    cv!.nomeCv = nomeArquivo;
    cv!.idContato = Number(id);

    if (idAtualizar && idAtualizar > 0) cv!.id = idAtualizar;

    cvActions.createFormData(cv!).then((data) => {
      const newList = cvs.concat(data!);
      setCVs(newList);
      setIdioma(undefined);
      setArquivoUrl('');
      setNomeArquivo('');
      openDrawerCV(false);
      toast.success('CV salvo com sucesso');
    });
    setLoading(false);
  };

  const handleSetIdioma = (event: any) => {
    let idiomaI = idiomasSelect.find((f) => f.id == event.target.value);
    setIdiomaSelected(event.target.value);
    setIdioma(idiomaI!);
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            height: '250px',
            width: '100%',
          }}
        >
          <ThemeProvider theme={createTheme({}, ptBR)}>
            <DataGrid
              getRowId={(row) => `${row?.id ?? 0}-${row?.nomeCv}`}
              rows={cvs ?? []}
              columns={columns}
              loading={loading}
              sx={{
                backgroundColor: 'white',
                border: '1px solid black',
                borderRadius: '14px',
              }}
            />
          </ThemeProvider>

          <ButtonAdd onClick={() => openDrawerCV(true)} />

          <Drawer
            style={{ minWidth: '250px' }}
            anchor={'right'}
            open={open}
            onClose={() => openDrawerCV(false)}
          >
            <Grid
              item
              xs={12}
              md={12}
              style={{ padding: '90px 30px', maxWidth: '500px', width: 'auto' }}
            >
              <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={2} md={2}>
                  <IconDrawer onClick={() => openDrawerCV(false)}>close</IconDrawer>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Typography variant="h5">{'Adicionar CV na Lista'}</Typography>
                </Grid>
              </Grid>

              <FormControl required fullWidth variant="outlined" sx={{ marginTop: '20px' }}>
                <InputLabel id="demo-simple-select-label">Idioma</InputLabel>
                <SelectStato
                  value={idiomaSelected}
                  onChange={handleSetIdioma}
                  required
                  label="Idioma"
                >
                  {idiomasSelect &&
                    idiomasSelect!.map((grau: IIdioma, index: number) => (
                      <MenuItem value={grau.id} key={index}>
                        {grau.nome}
                      </MenuItem>
                    ))}
                </SelectStato>
              </FormControl>

              <InputArquivo
                isEdit={editAcao}
                objOnEdit={acaoEmEdicao}
                setObjOnEdit={setAcaoEmEdicao}
                newObj={cv}
                setNewObj={setCV}
                nameFile={nomeArquivo}
                setNameFile={setNomeArquivo}
                urlFile={arquivoUrl}
                setUrlFile={setArquivoUrl}
              />

              <GridItemCadastroBtnSalvar
                item
                xs={11}
                md={11}
                sx={{
                  position: 'fixed',
                  right: '0px',
                  bottom: '0px',
                }}
              >
                <ButtonStato variant="contained" onClick={adicionarNaLista}>
                  Adicionar
                </ButtonStato>
              </GridItemCadastroBtnSalvar>
            </Grid>
          </Drawer>
        </Box>
      </Grid>
    </>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default CurriculoComponent;
