import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  IConfigArea,
  IConfigSegmento,
  IConfigSubArea,
  IFaixaSalarial,
  IIdioma,
  INivelDeCargo,
  ISetor,
  IUsuario,
} from '../../../../commons/genericTypes';
import {
  IEmpresaRelacionamento,
  IOrigemVaga,
  IVaga,
} from '../../../../commons/genericTypes/mercadoAlvo';
import {
  ButtonStato,
  GridTopPage,
  IconTituloStato,
  SelectStato,
} from '../../../../commons/styleds';
import { history } from '../../../../helpers/history';
import { useUsuarioActions } from '../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { useConfigAreaActions } from '../../../../states/config/ConfiguracaoSistema/areas/configArea.actions';
import { useFaixaSalarialActions } from '../../../../states/config/ConfiguracaoSistema/faixasSalariais/faixaSalarial.actions';
import { useIdiomaConfigActions } from '../../../../states/config/ConfiguracaoSistema/idiomas/idioma.actions';
import { useNivelDeCargoActions } from '../../../../states/config/ConfiguracaoSistema/niveisDeCargos/nivelDeCargo.actions';
import { useConfigSegmentoActions } from '../../../../states/config/ConfiguracaoSistema/segmentos/configSegmento.actions';
import { useSetorActions } from '../../../../states/config/ConfiguracaoSistema/setores/setor.actions';
import { useConfigSubAreaActions } from '../../../../states/config/ConfiguracaoSistema/subAreas/configSubArea.actions';
import { useEmpresaRelacionamentoActions } from '../../../../states/mesaDeMercado/empresaRelacionamento/empresaRelacionamento.actions';
import { useOrigemVagasActions } from '../../../../states/mesaDeMercado/origemVagas/origemVagas.actions';
import { BasePage, Container } from '../../../CRM/Empresa';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomTabPanel,
} from '../../../CRM/MiniBio/accordionAndTabPanel';
import { IParams } from '../../../CRM/MiniBio/Endereco';
import LocalVaga from './LocalVaga';
import MultiSelect from '../../../../components/Generics/MultiSelect';
import { GridItemCadastroBtnSalvar } from '../../../CRM/Comanda';
import { styled } from 'styled-components';
import { useVagaActions } from '../../../../states/mesaDeMercado/vagas/vaga.actions';
import dayjs from 'dayjs';

const empty: IVaga = {
  id: undefined,
  titulo: '',
  descricao: '',
  infoConfidencial: '',
  emailRecrutador: '',
  cidade: '',
  estado: '',
  pais: '',
  dataInicio: null,
  dataTermino: null,
  ativo: false,
  dtaCadastro: new Date(),
  vagaRelacionamento: '',
  idOrigem: undefined,
  meioCaptacao: undefined,
  ma: '',
  status: '',
  usuarioCadastro: undefined,

  idResponsavel: undefined,
  idFaixaSalarial: undefined,
  idEmpresaRelacionamento: null,

  idVagasSetores: [],
  idVagasAreas: [],
  idVagasSubareas: [],

  ogirem: { id: undefined, origem: '' },
  vagaSetores: [],
  responsavel: { id: undefined, nome: '' } as any,
};

const FollowUpDetalhes: React.FC = () => {
  const { id } = useParams<IParams>();
  const novoCadastro = id === 'nova';
  const methods = useForm<IVaga>({ defaultValues: empty });
  const meiosCaptacao = ['Empresa', 'Cliente', 'Captacao'];

  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const vagaActions = useVagaActions();

  const origensActions = useOrigemVagasActions();
  const [origens, setOrigens] = useState<IOrigemVaga[]>([]);

  const usuariosActions = useUsuarioActions();
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);

  const faixaSalarialActions = useFaixaSalarialActions();
  const [faixaSalariais, setFaixaSalariais] = useState<IFaixaSalarial[]>([]);

  const idiomasActions = useIdiomaConfigActions();
  const [idiomas, setIdiomas] = useState<IIdioma[] | undefined>(undefined);

  const nivelDeCargoActions = useNivelDeCargoActions();
  const [niveisDeCargos, setNiveisDeCargos] = useState<INivelDeCargo[]>([]);

  const configAreaActions = useConfigAreaActions();
  const [areas, setAreas] = useState<IConfigArea[]>([]);

  const configSubAreaActions = useConfigSubAreaActions();
  const [subAreas, setSubAreas] = useState<IConfigSubArea[] | undefined>(undefined);

  const configSegmentoActions = useConfigSegmentoActions();
  const [segmentos, setSegmento] = useState<IConfigSegmento[] | undefined>(undefined);

  const configSetorActions = useSetorActions();
  const [setores, setSetor] = useState<ISetor[] | undefined>(undefined);

  const empresasActions = useEmpresaRelacionamentoActions();
  const [empresas, setEmpresas] = useState<IEmpresaRelacionamento[] | undefined>(undefined);

  const carregarEmpresas = () => {
    const empresasPromise = empresasActions
      .list(false, `?pagina=${1}&tamanhoPagina=${15000}`)
      .then((data) => setEmpresas(data.itens));
  };

  async function fetchData() {
    try {
      carregarEmpresas();
      const areaPromise = configAreaActions.list();
      const subAreaPromise = configSubAreaActions.list();
      const segmentoPromise = configSegmentoActions.list();
      const faixaSalarialPromise = faixaSalarialActions.list();
      const setorPromise = configSetorActions.list();
      const nivelDeCargoPromise = nivelDeCargoActions.list();
      const idiomasPromise = idiomasActions.list();
      const usuariosPromise = usuariosActions.buscarUsuarios(false);
      const origensPromise = origensActions.list();

      const [
        areas,
        subAreas,
        segmentos,
        faixasSalariais,
        setores,
        niveisDeCargos,
        idiomas,
        usuarios,
        origens,
      ] = await Promise.all([
        areaPromise,
        subAreaPromise,
        segmentoPromise,
        faixaSalarialPromise,
        setorPromise,
        nivelDeCargoPromise,
        idiomasPromise,
        usuariosPromise,
        origensPromise,
      ]);

      setAreas(areas);
      setSubAreas(subAreas);
      setSegmento(segmentos);
      setFaixaSalariais(faixasSalariais);
      setSetor(setores);
      setNiveisDeCargos(niveisDeCargos);
      setIdiomas(idiomas);
      setUsuarios(usuarios.resultado);
      setOrigens(origens);
    } catch (err: any) {
      toast.warn('Erro ao carregar os dados: ' + err.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!novoCadastro) {
      vagaActions.get(Number(id)!).then((data) => {
        fetchData().then(() => {
          fillMethodsMultiSelect(data);
          methods.reset(data, { keepDefaultValues: true });
        });
      });
    } else fetchData();
  }, [id]);

  const fillMethodsMultiSelect = (data: IVaga) => {
    data.idVagasAreas = data.vagaAreas?.map((a) => a.idArea);
    data.idVagasSubareas = data.vagaSubareas?.map((a) => a.idSubArea);
    data.idVagasSetores = data.vagaSetores?.map((a) => a.idSetor);
    // data.idVagasNiveisCargos = data.vagaNiveisCargos?.map(a => a.idNivelDeCargo);
  };

  const fillDataMultiSelect = (data: IVaga, idVaga: number) => {
    data.vagaAreas = areas
      ?.filter((area) => data.idVagasAreas!.includes(area.id!))
      .map((x) => {
        return { idArea: x.id!, idVaga: idVaga || 0 };
      });
    data.vagaSubareas = subAreas
      ?.filter((subArea) => data.idVagasSubareas!.includes(subArea.id!))
      .map((x) => {
        return { idSubArea: x.id!, idVaga: idVaga || 0 };
      });
    data.vagaSetores = setores
      ?.filter((setor) => data.idVagasSetores!.includes(setor.id!))
      .map((x) => {
        return { idSetor: x.id!, idVaga: idVaga || 0 };
      });
    // data.vagaNiveisCargos = niveisDeCargos
    // ?.filter((nivel) => data.idVagasNiveisCargos!.includes(nivel.id!))
    // .map((x) => {
    // return { idNivelCargo: x.id!, idVaga: idVaga };
    // });
    // data.vagaIdiomas = idiomas
    // ?.filter((idioma) => data.idVagasIdiomas!.includes(idioma.id!))
    // .map((x) => {
    // return { idIdioma: x.id!, idVaga: idVaga };
    // });
    return data;
  };

  const onSubmit = async (data: IVaga) => {
    const idVaga = Number(id!);
    data = fillDataMultiSelect(data, idVaga);

    try {
      if (novoCadastro) await vagaActions.create(data);
      else await vagaActions.update(idVaga, data);
      toast.success('Vaga salva com sucesso');
      history.goBack();
    } catch (error) {
      toast.error('Erro ao salvar vaga');
    }
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant="h5">Follow Up</Typography>
        </Grid>

        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato onClick={() => history.goBack()} type="submit" variant="contained">
            <Icon>undo</Icon>
            Voltar
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Container container rowSpacing={2} style={{ paddingTop: '0' }}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {novoCadastro ? 'Cadastrar nova Vaga' : 'Editar Vaga'}
          </Typography>
          <Divider sx={{ maxWidth: '40%' }} />
        </Grid>

        {loading && <LinearProgress />}

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box sx={{ width: '100%' }}>
              <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Informações da Vaga</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={1} sx={{ marginTop: '10px' }}>
                    <Grid item xs={12} sm={8}>
                      <Controller
                        name="titulo"
                        control={methods.control}
                        render={({ field }) => (
                          <TextField {...field} label="Título da Vaga" fullWidth />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Controller
                        name="descricao"
                        control={methods.control}
                        render={({ field }) => (
                          <TextField {...field} label="Descrição" fullWidth multiline rows={4} />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={2}>
                      <Controller
                        name="dataInicio"
                        control={methods.control}
                        render={({ field }) => (
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            localeText={{
                              clearButtonLabel: 'Empty',
                              todayButtonLabel: 'Now',
                            }}
                          >
                            <DesktopDatePicker
                              {...field}
                              value={field.value ? dayjs(field.value) : undefined}
                              label="Data Início"
                              format="DD/MM/YYYY"
                              slotProps={{
                                textField: {
                                  required: true,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Controller
                          name="dataTermino"
                          control={methods.control}
                          render={({ field }) => (
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              localeText={{
                                clearButtonLabel: 'Empty',
                                todayButtonLabel: 'Now',
                              }}
                            >
                              <DesktopDatePicker
                                {...field}
                                value={field.value ? dayjs(field.value) : undefined}
                                label="Data Início"
                                format="DD/MM/YYYY"
                                slotProps={{
                                  textField: {
                                    required: true,
                                  },
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <Controller
                        name="infoConfidencial"
                        control={methods.control}
                        render={({ field }) => (
                          <TextField {...field} label="Inf. Confidencial" fullWidth />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Controller
                        name="idOrigem"
                        control={methods.control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="origem-label">Origem</InputLabel>
                            <SelectStato {...field} label="Origem">
                              {origens.map((origem, index) => (
                                <MenuItem value={origem.id} key={index}>
                                  {origem.origem}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Localidade da Vaga</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <LocalVaga />
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Detalhamento da Vaga</Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={1} sx={{ marginTop: '10px' }}>
                    <Grid item xs={12} md={6}>
                      <Controller
                        name="idResponsavel"
                        control={methods.control}
                        render={({ field }) => (
                          <FormControl fullWidth>
                            <InputLabel id="agente-label">Agente de Mercado</InputLabel>
                            <SelectStato {...field} label="Agente de Mercado">
                              {usuarios?.map((usuario, index) => (
                                <MenuItem value={usuario.id} key={index}>
                                  {usuario.nome}
                                </MenuItem>
                              ))}
                            </SelectStato>
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={8}>
                      <Box display="flex" alignItems="center">
                        <Controller
                          name="idEmpresaRelacionamento"
                          control={methods.control}
                          render={({ field }) => (
                            <FormControl sx={{ flexGrow: 1 }}>
                              <InputLabel id="empresa-label">Empresa</InputLabel>
                              <SelectStato {...field} label="Empresa">
                                {empresas?.map((empresa, index) => (
                                  <MenuItem value={empresa.id} key={index}>
                                    {empresa.empresa}
                                  </MenuItem>
                                ))}
                              </SelectStato>
                            </FormControl>
                          )}
                        />
                        <Tooltip title="Carregar Empresas">
                          <IconButton onClick={carregarEmpresas} sx={{ ml: 1 }}>
                            <RefreshIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Adicionar Nova">
                          <IconButton
                            component="a"
                            href={`${window.location.origin}/empresas-relacionamento`}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ ml: 1 }}
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="idVagasAreas"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {areas && (
                              <MultiSelect
                                opcoes={areas as IConfigArea[]}
                                setValor={onChange}
                                valor={value || []}
                                label="Áreas"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="idVagasSubareas"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {subAreas && (
                              <MultiSelect
                                opcoes={subAreas as IConfigSubArea[]}
                                setValor={onChange}
                                valor={value || []}
                                label="Subareas"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Controller
                        control={methods.control}
                        name="idVagasSetores"
                        render={({ field: { ref, onChange, value, ...field } }) => (
                          <FormControl fullWidth variant="outlined">
                            {setores && (
                              <MultiSelect
                                opcoes={setores as ISetor[]}
                                setValor={onChange}
                                valor={value || []}
                                label="Setores"
                              />
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <DivButton>
              <ButtonStato variant="contained" type="submit">
                Salvar
              </ButtonStato>
            </DivButton>
          </form>
        </FormProvider>
      </Container>
    </BasePage>
  );
};

const DivButton = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 25px;
`;

export default FollowUpDetalhes;
