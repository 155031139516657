import {
  Box,
  Button,
  createTheme,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Icon,
  ThemeProvider,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid, GridCellParams, GridColDef, GridEventListener, ptBR } from '@mui/x-data-grid';
import { usePermissions } from '../../../components/PermissionsProvider';
import { BasePage, Container } from '../../CRM/Empresa';
import { ButtonStato, GridTopPage, IconTituloStato } from '../../../commons/styleds';
import { history } from '../../../helpers/history';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useEmpresaRelacionamentoActions } from '../../../states/mesaDeMercado/empresaRelacionamento/empresaRelacionamento.actions';
import { IEmpresaRelacionamento } from '../../../commons/genericTypes/mercadoAlvo';
import { createColumns } from './columns';

const EmpresasRelacionamento: React.FC = () => {
  const actions = useEmpresaRelacionamentoActions();
  const [loading, setLoading] = useState<boolean>(true);
  const [idDeletar, setIdDeletar] = useState<number>();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<IEmpresaRelacionamento[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const { podeEditar, podeExcluir } = usePermissions();
  const modulo = 'mesa de mercado';
  const canExclude = podeExcluir(modulo);
  const canEdit = podeEditar(modulo);

  const getEmpresas = () => {
    actions
      .list(false, `?pagina=${page}&tamanhoPagina=${pageSize}`)
      .then((resp) => {
        setRows(resp.itens);
        setRowCount(resp.totalItens);
      })
      .catch(() => toast.error('Erro ao buscar empresas'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getEmpresas();
  }, [page, pageSize]);

  const handleEditar = (obj: any) => {
    history.push(`/empresas-relacionamento/${obj.id}`);
  };
  const handleDeletar = (obj: any) => {
    setIdDeletar(obj.id);
    setOpen(true);
  };
  const columns = createColumns({ canExclude, handleEditar, handleDeletar });

  const handleClose = () => {
    setOpen(false);
  };

  const deleteValue = () => {
    actions
      .delete(idDeletar!)
      .then(() => {
        const sub = rows.filter((f) => f.id !== idDeletar);
        setRows(sub);
      })
      .catch((err: any) => toast.warn(err))
      .finally(() => setOpen(false));
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    handleEditar(params.row);
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant="h5">Empresas</Typography>
        </Grid>

        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('empresas-relacionamento/nova')}
            type="submit"
            variant="contained"
          >
            <Icon>add</Icon>
            Adicionar Empresa
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={rows}
                columns={columns}
                pagination
                paginationMode="server"
                pageSize={pageSize}
                rowsPerPageOptions={[5, 10, 20]}
                rowCount={rowCount}
                onPageChange={(newPage) => setPage(newPage + 1)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

export default EmpresasRelacionamento;
