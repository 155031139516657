import { styled } from 'styled-components';
import Menu from '../../../../components/FerramentasAdministrativas/Menu';
import { ButtonStato, GridContainer, TextFieldStato } from '../../../../commons/styleds';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Icon,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IConfigSegmento } from '../../../../commons/genericTypes';
import { useConfigSegmentoActions } from '../../../../states/config/ConfiguracaoSistema/segmentos/configSegmento.actions';
import { DataGrid, GridEventListener, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { history } from '../../../../helpers/history';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const empty: IConfigSegmento = {
  descricao: '',
  ativo: true,
};

const Segmento: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const configSegmentoActions = useConfigSegmentoActions();

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [segmentos, setSegmentos] = useState<IConfigSegmento[]>([]);
  const [segmentoTemp, setSegmentoTemp] = useState<IConfigSegmento>(empty);
  const [selectedSegmentos, setSelectedSegmentos] = useState<IConfigSegmento[] | undefined>(
    undefined,
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'descricao',
      headerName: 'Descrição',
      width: 500,
    },
    {
      field: 'action',
      headerName: ' ',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IConfigSegmento;
        return (
          <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  const deleteValue = () => {
    configSegmentoActions
      .delete(segmentoTemp.id!)
      .then((resp: IConfigSegmento[]) => {
        const sub = segmentos.filter((f) => f.id !== segmentoTemp.id);
        setSegmentos(sub);
        atualizarFormulario(empty);
      })
      .catch((err: any) => toast.warn(err));

    methods.reset(segmentoTemp, { keepDefaultValues: true });
    setIdAtualizar(segmentoTemp.id);
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const HandleAtivoInativo = (usuario: any) => {
    usuario.ativo = usuario.ativo ? false : true;
    AtualizarUsuario(usuario);
    atualizarFormulario(empty);
  };

  const AtualizarUsuario = (data: any) => {
    configSegmentoActions
      .update(data.id, data)
      .then((result) => {
        const sub = segmentos.filter((f) => f.id !== result.id);
        setSegmentos(sub.concat(result));
        atualizarFormulario(empty);
        toast.success('Atualizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };

  const onSubmit = async (data: any) => {
    setLoadingSave(true);

    if (data.id > 0) AtualizarUsuario(data);
    else
      configSegmentoActions
        .create(data)
        .then((result) => {
          setSegmentos(segmentos.concat(result));
          atualizarFormulario(empty);
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });

    setLoadingSave(false);
  };

  useEffect(() => {
    configSegmentoActions
      .list()
      .then((resp: IConfigSegmento[]) => setSegmentos(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setSegmentoTemp(obj);
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
    setSegmentoTemp(empty);
  };

  return (
    <BasePage>
      <Menu />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato id="formArea" onSubmit={methods.handleSubmit(onSubmit)}>
                <h3>{idAtualizar ? `Atualizar - ID: ${idAtualizar}` : 'Novo Cadastro'}</h3>
                <Controller
                  control={methods.control}
                  name="descricao"
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant="outlined">
                      <FormInput {...field} inputRef={ref} value={field.value} label="Descricao" />
                    </FormControl>
                  )}
                />

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Controller
                    control={methods.control}
                    name="ativo"
                    render={({ field }) => (
                      <FormControlLabelStato
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                            //defaultChecked={true}
                          />
                        }
                        label="Ativo"
                      />
                    )}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type="submit" variant="contained">
                    Salvar
                  </ButtonStato>
                  {idAtualizar ? (
                    <ButtonStato
                      color="error"
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant="contained"
                    >
                      Cancelar
                    </ButtonStato>
                  ) : (
                    <></>
                  )}
                </div>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={segmentos}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default Segmento;
