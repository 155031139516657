import {
  Box,
  FormControl,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import {
  ButtonStato,
  GridContainerCenter,
  GridTopPage,
  IconArrowBackStato,
  IconTituloStato,
  TextFieldStato,
} from '../../../commons/styleds';
import React, { useEffect, useState } from 'react';
import { history } from '../../../helpers/history';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { IAuth, IContato } from '../../../commons/genericTypes';
import EnderecoComponent from './Endereco';
import TelefoneComponent from './Telefone';
import IdiomaComponent from './Idioma';
import CurriculoComponent from './Curriculo';
import HistoricoComponent from './Historico';
import GraduacaoComponent from './Graduacao';
import { useMountEffect } from '../../../commons/utils';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CustomTabPanel,
  a11yProps,
} from './accordionAndTabPanel';
import { useContatoActions } from '../../../states/crm/contatos/contato.actions';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ProgramaCrm from '../Contato/Programa';
import { NumericFormat } from 'react-number-format';
import { contatoAtom } from '../../../states/crm/contatos/ContatoState';
import { toast } from 'react-toastify';
import MercadoAlvo from '../MercadoAlvo';
import MercadoAlvoCrm from '../MercadoAlvo';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import PPC from '../Perfil';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { usePermissions } from '../../../components/PermissionsProvider';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('America/Sao_Paulo');

const emptyMiniBio: IContato = {
  nome: '',
  cpf: '',
  email: '',
  dtNascimento: dayjs(Date.now()),
  sexo: '',
  idEstadoCivil: undefined,
  ultimoCargo: '',
  ultimaRemuneracao: '',
  salarioPretendido: 0,
  idDaynamics: '',
};

interface IParams {
  id?: string;
}

const MiniBio: React.FC = () => {
  const { id } = useParams<IParams>();
  const usuario = useRecoilValue(authAtom);
  const methods = useForm<IContato>({ defaultValues: emptyMiniBio });
  const contatoActions = useContatoActions();
  const ContaroId: number | undefined = methods.watch('id');
  const [value, setValue] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const novoUsuario = false;

  const modulo = 'crm';
  const { podeEditar, podeExcluir } = usePermissions();
  const adm = podeExcluir(modulo);
  const canEdit = podeEditar(modulo);

  const onMount = () => {
    setLoading(false);
    contatoActions.get(Number(id)).then((result) => {
      methods.reset({
        ...result,
        dtNascimento: result.dtNascimento ? dayjs(result.dtNascimento) : null,
      });
    });
  };
  useMountEffect(onMount);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSubmit = async (data: IContato) => {
    setLoading(true);

    const request: any = { ...data };
    request.ultimaRemuneracao = getNumber(data?.ultimaRemuneracao ?? '');
    request.salarioPretendido = getNumber(String(data?.salarioPretendido) ?? '');

    await contatoActions.patch(Number(request.id), request);
    toast.success('Contato atualizado');

    setLoading(false);
  };

  const getNumber = (dataString: string) => {
    return Number(dataString.replace('R$', '').replace('.', '').replace(',', '.'));
  };

  return (
    <>
      <GridTopPage item xs={12} md={12}>
        <IconArrowBackStato onClick={() => history.push('/crm/contatos')}>
          arrow_back
        </IconArrowBackStato>
        <IconTituloStato>assignment</IconTituloStato>
        <Typography variant="h5">Contato #{ContaroId}</Typography>
      </GridTopPage>

      <Grid
        item
        xs={12}
        md={12}
        sx={{
          alignContent: 'center',
          alignItems: 'center',
          padding: '0px 41px',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              margin: '0px 25px !important',
            }}
          >
            <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
              <Tab label="Dados" {...a11yProps(0)} />
              <Tab label="Programas" {...a11yProps(1)} />
              <Tab label="Mercado Alvo" {...a11yProps(2)} />
              <Tab label="Perfil" {...a11yProps(3)} />
              <Tab label="Anexo" {...a11yProps(4)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            {loading && <LinearProgress />}
            <FormProvider {...methods}>
              {!loading && (
                <>
                  <Accordion defaultExpanded style={{ borderRadius: '10px' }}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <Typography>Informações</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <form
                        id="formUsuario"
                        style={{ margin: '-10px -20px !important' }}
                        onSubmit={methods.handleSubmit(onSubmit)}
                      >
                        <GridCadastro container id="cadastro-usuario" rowGap={1.5}>
                          <GridItemCadastro item xs={12} md={5}>
                            <Controller
                              control={methods.control}
                              name="nome"
                              render={({ field: { ref, ...field } }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    label="Nome"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={5}>
                            <Controller
                              control={methods.control}
                              name="cpf"
                              render={({ field }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    type="cpf"
                                    label="CPF"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={2}>
                            <Controller
                              control={methods.control}
                              name="sexo"
                              render={({ field }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    type="sexo"
                                    label="Sexo"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={5}>
                            <Controller
                              control={methods.control}
                              name="email"
                              render={({ field }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    type="email"
                                    label="Email"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={3}>
                            <Controller
                              control={methods.control}
                              name="dtNascimento"
                              render={({ field }) => (
                                <FormControl fullWidth variant="outlined">
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                      {...field}
                                      value={field.value ?? null}
                                      label="Data Nascimento"
                                      format="DD/MM/YYYY"
                                      disabled={!canEdit}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={4}>
                            <Controller
                              control={methods.control}
                              name="estadoCivil"
                              render={({ field }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    type="estadoCivil"
                                    label="Estado Civil"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={4}>
                            <Controller
                              control={methods.control}
                              name="ultimoCargo"
                              render={({ field: { ref, ...field } }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    label="Último Cargo"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={4}>
                            <Controller
                              control={methods.control}
                              name="ultimaRemuneracao"
                              render={({ field: { ref, ...field } }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <NumericFormat
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    prefix="R$"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    label="Último Salário"
                                    customInput={TextFieldStato}
                                    allowLeadingZeros={false}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={4}>
                            <Controller
                              control={methods.control}
                              name="salarioPretendido"
                              render={({ field: { ref, ...field } }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <NumericFormat
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    prefix="R$"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    label="Salario Pretendido"
                                    customInput={TextFieldStato}
                                    allowLeadingZeros={false}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={4}>
                            <Controller
                              control={methods.control}
                              name="linkedIn"
                              render={({ field: { ref, ...field } }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    label="LinkedIn"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          <GridItemCadastro item xs={12} md={4}>
                            <Controller
                              control={methods.control}
                              name="idDaynamics"
                              render={({ field: { ref, ...field } }) => (
                                <FormControl required fullWidth variant="outlined">
                                  <TextFieldStato
                                    {...field}
                                    inputRef={ref}
                                    value={field.value}
                                    label="ID Daynamics"
                                    disabled={!canEdit}
                                  />
                                </FormControl>
                              )}
                            />
                          </GridItemCadastro>

                          {canEdit && (
                            <GridItemCadastroBtnSalvar item xs={11} md={11}>
                              <ButtonStato type="submit" variant="contained" disabled={!canEdit}>
                                Salvar
                              </ButtonStato>
                            </GridItemCadastroBtnSalvar>
                          )}
                        </GridCadastro>
                      </form>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded style={{ borderRadius: '10px' }}>
                    <AccordionSummary aria-controls="endereco-content" id="endereco-header">
                      <Typography>Endereços</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <EnderecoComponent />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded style={{ borderRadius: '10px' }}>
                    <AccordionSummary aria-controls="telefone-content" id="telefone-header">
                      <Typography>Telefones</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TelefoneComponent />
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded style={{ borderRadius: '10px' }}>
                    <AccordionSummary aria-controls="idioma-content" id="idioma-header">
                      <Typography>Idiomas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <IdiomaComponent novoUsuario={novoUsuario}></IdiomaComponent>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded style={{ borderRadius: '10px' }}>
                    <AccordionSummary aria-controls="cvs-content" id="cvs-header">
                      <Typography>CVs</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CurriculoComponent novoUsuario={novoUsuario}></CurriculoComponent>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion style={{ borderRadius: '10px' }} defaultExpanded>
                    <AccordionSummary aria-controls="graduacao-content" id="graduacao-header">
                      <Typography>Formações Acadêmicas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <GraduacaoComponent novoUsuario={novoUsuario}></GraduacaoComponent>
                    </AccordionDetails>
                  </Accordion>

                  <Accordion defaultExpanded style={{ borderRadius: '10px' }}>
                    <AccordionSummary aria-controls="historico-content" id="historicod-header">
                      <Typography>Histórico Profissional</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <HistoricoComponent />
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
            </FormProvider>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <ProgramaCrm />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={2}>
            <MercadoAlvoCrm />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={3}>
            <PPC></PPC>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={4}>
            Anexo
          </CustomTabPanel>
        </Box>
      </Grid>
    </>
  );
};

const GridCadastro = styled(GridContainerCenter)`
  width: 97% !important;
  margin-right: 0px !important;
  display: flex;
  justify-content: flex-start !important;
  padding: 15px 0%;
`;

const GridItemCadastro = styled(Grid)`
  padding: 5px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

export default MiniBio;
