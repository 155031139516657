import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Icon from '@mui/material/Icon';
import { GridColDef, GridCellParams } from '@mui/x-data-grid';
import { IEmpresaRelacionamento } from '../../../commons/genericTypes/mercadoAlvo';

interface ColumnProps {
  canExclude: boolean;
  handleEditar: (obj: any) => void;
  handleDeletar: (obj: any) => void;
}

export const createColumns = ({
  canExclude,
  handleEditar,
  handleDeletar,
}: ColumnProps): GridColDef[] => [
  {
    field: 'id',
    headerName: 'Id',
    width: 80,
  },
  {
    field: 'empresa',
    headerName: 'Empresa',
    width: 200,
  },
  {
    field: 'contato',
    headerName: 'Contato',
    width: 220,
  },
  {
    field: 'agenteDeMercado.Nome',
    headerName: 'Agente de Mercado',
    width: 220,
    valueGetter: (params: GridCellParams) => {
      const obj = params.row as IEmpresaRelacionamento;
      return obj.agenteDeMercado?.nome;
    },
  },
  {
    field: 'nivelRelacionamento.nome',
    headerName: 'Nível de Relacionamento',
    width: 200,
    renderCell: (params: GridCellParams) => {
      const obj = params.row as IEmpresaRelacionamento;
      return (
        <>
          <div
            style={{
              border: `1px solid ${obj.nivelRelacionamento?.hexadecimal}`,
              padding: '10px 15px',
              borderRadius: '20px',
              color: obj.nivelRelacionamento?.hexadecimal,
            }}
          >
            {obj.nivelRelacionamento?.nome}
          </div>
        </>
      );
    },
  },
  {
    field: 'segmento',
    headerName: 'Segmento',
    width: 220,
    valueGetter: (params: GridCellParams) => {
      const obj = params.row as IEmpresaRelacionamento;
      return obj.segmento?.descricao;
    },
  },
  {
    field: 'setor',
    headerName: 'Setor',
    width: 220,
  },
  {
    field: 'ultimoContato',
    headerName: 'Último Contato',
    width: 160,
  },
  {
    field: 'action',
    headerName: 'Ações',
    width: 100,
    editable: true,
    renderCell: (params: GridCellParams) => {
      const obj = params.row as any;
      return (
        <>
          <Icon sx={{ cursor: 'pointer' }} onClick={() => handleEditar(obj)}>
            <EditIcon />
          </Icon>
          {canExclude && (
            <Icon onClick={() => handleDeletar(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
              <DeleteIcon />
            </Icon>
          )}
        </>
      );
    },
  },
];
