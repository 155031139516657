import { Box, createTheme, Grid, ThemeProvider, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { styled } from 'styled-components';
import { PaperRelatorio } from '..';
import { StatusValidacaoEnum } from '../../../../commons/enums';
import { IComandaEtapaReunioes } from '../../../../commons/genericTypes';
import { useComandaEtapaReuniaoActions } from '../../../../states/config/Programas/comandaEtapaReunioes/comandaEtapaReuniao.actions';

interface ITimesheetResultado {
  consultor: string;
  dataInicio: Date;
  dataFim: Date;
  status: string;
  resultado: IComandaEtapaReunioes[];
}

const TimesheetRestultado: React.FC<ITimesheetResultado> = (props) => {
  const { consultor, dataInicio, dataFim, status, resultado } = props;
  const actions = useComandaEtapaReuniaoActions();
  const [reunioes, setReunioes] = useState<IComandaEtapaReunioes[]>(resultado ?? []);

  const revisarReuniao = (id: number | string, status: StatusValidacaoEnum) => {
    actions
      .revisar(id, { statusValidacao: status })
      .then(() => {
        toast.success('Reunão revisada com sucesso');
        const index = reunioes.findIndex((item) => item.id === id);
        reunioes[index].statusValidacao = status;
        setReunioes([...reunioes]);
      })
      .catch(() => toast.error('Falha ao revisar Reunão'));
  };

  const columns: GridColDef[] = [
    {
      field: 'unidade',
      headerName: 'Unidade',
      width: 70,
      valueGetter: (params: GridCellParams) => {
        const data = params.row as any;
        return data.comandaIdNavigation.modeloComercial;
      },
    },
    {
      field: 'nome',
      headerName: 'Assessorado',
      width: 160,
      valueGetter: (params: GridCellParams) => {
        const data = params.row as any;
        return data.comandaIdNavigation.contato.nome;
      },
    },
    {
      field: 'tbEtapaIdNavigation.nome',
      headerName: 'Etapa',
      width: 160,
      valueGetter: (params: GridCellParams) => {
        const data = params.row as any;
        return data.tbEtapaIdNavigation.nome;
      },
    },
    {
      field: 'dtPrevista',
      headerName: 'Data',
      width: 110,
      valueGetter: (params: GridCellParams) => {
        const data = params.row.dtPrevista as Date;
        return moment(data).format('DD/MM/YYYY');
      },
    },
    {
      field: 'dtRealizadaInicio',
      headerName: 'Inicio',
      width: 80,
      valueGetter: (params: GridCellParams) => {
        const data = params.row.dtRealizadaInicio as Date;
        return moment(data).format('HH:mm');
      },
    },
    {
      field: 'dtRealizadaFim',
      headerName: 'Fim',
      width: 60,
      valueGetter: (params: GridCellParams) => {
        const data = params.row.dtRealizadaFim as Date;
        return moment(data).format('HH:mm');
      },
    },
    {
      field: 'horasConsumidas',
      headerName: 'Horas consumidas',
      width: 90,
      valueGetter: (params: GridCellParams) => {
        const data = params.row as any;
        const inicio = moment(data.dtRealizadaInicio);
        const fim = moment(data.dtRealizadaFim);
        const duration = moment.duration(fim.diff(inicio));
        const horas = duration.asHours();
        return `${horas} hrs`;
      },
    },
    {
      field: 'valorPago',
      headerName: 'Valor Pago',
      width: 90,
      valueGetter: (params: GridCellParams) => {
        const data = params.row as any;
        const inicio = moment(data.dtRealizadaInicio);
        const fim = moment(data.dtRealizadaFim);
        const duration = moment.duration(fim.diff(inicio));
        const horas = duration.asHours();

        const valor = data.valorHora * horas;
        return `R$ ${valor}`;
      },
    },
    {
      field: 'bu',
      headerName: 'BU',
      width: 110,
      valueGetter: (params: GridCellParams) => {
        const data = params.row as any;
        return data.comandaIdNavigation.bUs;
      },
    },
    {
      field: '',
      headerName: 'Contabilizar/Validar',
      width: 150,
      editable: true,
      renderCell: (params: GridCellParams) => {
        const comanda = params.row as IComandaEtapaReunioes;

        if (comanda.statusValidacao !== StatusValidacaoEnum.PENDENTE)
          return <>{comanda.statusValidacao}</>;

        return (
          <>
            <SpanValidar
              style={{ color: 'green' }}
              onClick={() => revisarReuniao(comanda.id!, StatusValidacaoEnum.APROVADO)}
            >
              APROVADO
            </SpanValidar>
            {`  /  `}
            <SpanValidar
              style={{ color: 'red' }}
              onClick={() => revisarReuniao(comanda.id!, StatusValidacaoEnum.REPROVADO)}
            >
              REPROVADO
            </SpanValidar>
          </>
        );
      },
    },
  ];

  return (
    <PaperRelatorio sx={{ margin: '30px 0', height: '100%' }} elevation={3}>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <Typography variant="h3">Parâmetros:</Typography>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Consultor:</b>
            </Typography>
            <Typography variant="body2">{consultor}</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Status:</b>
            </Typography>
            <Typography variant="body2">{status}</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Data início:</b>
            </Typography>
            <Typography variant="body2">
              {new Date(dataInicio).toLocaleDateString('pt-BR')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="body1">
              <b>Data fim:</b>
            </Typography>
            <Typography variant="body2">{new Date(dataFim).toLocaleDateString('pt-BR')}</Typography>
          </Grid>
        </Grid>

        <ThemeProvider theme={createTheme({}, ptBR)}>
          <DataGrid
            rows={reunioes}
            columns={columns}
            sx={{
              backgroundColor: 'white',
              borderRadius: '20px',
              height: '500px',
              marginTop: '15px',
            }}
          />
        </ThemeProvider>
      </Box>
    </PaperRelatorio>
  );
};

const SpanValidar = styled.span`
  font-size: 12px;
  cursor: pointer;
`;

export default TimesheetRestultado;
