import { styled } from 'styled-components';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  CircularProgress,
  Icon,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { DataGrid, GridEventListener, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { IEmpresa } from '../../../commons/genericTypes';
import { useEmpresasActions } from '../../../states/empresas/empresa.actions';
import { useRecoilValue } from 'recoil';
import {
  ButtonStato,
  GridContainer,
  GridTopPage,
  IconTituloStato,
  TextFieldStato,
} from '../../../commons/styleds';
import { authAtom } from '../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../helpers/history';
import EditIcon from '@mui/icons-material/Edit';

const Empresa: React.FC = () => {
  const auth = useRecoilValue(authAtom);
  const empresaActions = useEmpresasActions();
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [idDeletar, setIdDeletar] = useState<number>();
  const [empresas, setEmpresas] = useState<IEmpresa[]>([]);
  const [selectedEmpresas, setSelectedEmpresas] = useState<IEmpresa[] | undefined>(undefined);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      width: 200,
    },
    {
      field: 'nomefantasia',
      headerName: 'Nome Fantasia',
      width: 220,
    },
    {
      field: 'razaosocial',
      headerName: 'Razão Social',
      width: 220,
    },
    {
      field: 'action',
      headerName: 'Ações',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEmpresa;
        return (
          <>
            <Icon sx={{ cursor: 'pointer' }} onClick={() => handleEditar(obj)!}>
              {' '}
              <EditIcon />{' '}
            </Icon>
            <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
              delete
            </Icon>
          </>
        );
      },
    },
  ];

  const deleteValue = () => {
    empresaActions
      .delete(idDeletar!)
      .then((resp: IEmpresa[]) => {
        const sub = empresas.filter((f) => f.id !== idDeletar);
        setEmpresas(sub);
      })
      .catch((err: any) => toast.warn(err));
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  useEffect(() => {
    empresaActions
      .list()
      .then((resp: IEmpresa[]) => setEmpresas(resp))
      .catch((err: any) => toast.warn(err));

    setLoading(false);
  }, []);

  const handleClickOpen = (obj: any) => {
    setIdDeletar(obj.id);
    setOpen(true);
    setLoadingForm(true);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    handleEditar(params.row);
  };

  const handleEditar = (empresa: IEmpresa) => {
    history.push('/crm/empresa/' + empresa.id);
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
  };

  return (
    <BasePage>
      <GridTopPage item xs={12} md={12}>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <IconTituloStato>store</IconTituloStato>
          <Typography variant="h5">Empresas</Typography>
        </Grid>

        <Grid
          item
          xs={8}
          md={8}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            paddingRight: '40px',
          }}
        >
          <ButtonStato
            onClick={() => history.push('/crm/empresa/nova')}
            type="submit"
            variant="contained"
          >
            <Icon>add</Icon>
            Adicionar Empresa
          </ButtonStato>
        </Grid>
      </GridTopPage>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={empresas}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

export const BasePage = styled.div`
  padding: 15px;
`;

export const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

export default Empresa;
