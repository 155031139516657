import { styled } from 'styled-components';
import Menu from '../../../../components/FerramentasAdministrativas/Menu';
import {
  ButtonStato,
  GridContainer,
  SelectStato,
  TextFieldStato,
} from '../../../../commons/styleds';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  CircularProgress,
  MenuItem,
  InputLabel,
  FormControl,
  Icon,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IEtapa, IMetodologia } from '../../../../commons/genericTypes';
import { DataGrid, GridEventListener, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import { history } from '../../../../helpers/history';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useEtapaActions } from '../../../../states/config/Programas/etapas/etapa.actions';
import EditorHtml from '../../../../components/EditorHtml/EditorHtml';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { useRecoilValue } from 'recoil';
import { useMetodologiaActions } from '../../../../states/config/Programas/metodologias/metodologia.actions';

const empty: IEtapa = {
  id: 0,
  nome: '',
  texto: '',
  idPrazo: undefined,
  idMetodologia: undefined,
  ordem: undefined,
  ativo: true,
};

const Etapa: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const etapaActions = useEtapaActions();
  const auth = useRecoilValue(authAtom);
  const metodologiaActions = useMetodologiaActions();
  const [metodologias, setMetodologias] = useState<IMetodologia[]>([]);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSave, setLoadingSave] = useState<boolean>(false);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [etapas, setEtapas] = useState<IEtapa[]>([]);
  const [etapasTemp, setEtapasTemp] = useState<IEtapa>({});
  const [open, setOpen] = useState(false);

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'nome',
      headerName: 'Nome',
      width: 400,
    },
    {
      field: 'idMetodologiaNavigation.nome',
      headerName: 'Metodologia',
      width: 200,
      valueGetter: (params: GridCellParams) => {
        let obj = params.row as IEtapa;
        return obj.idMetodologiaNavigation?.nome;
      },
    },
    {
      field: 'action',
      headerName: ' ',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IEtapa;
        return (
          <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  const onSubmit = async (data: any) => {
    setLoadingSave(true);
    if (data.idMetodologia == 0 || data.idMetodologia == undefined) {
      toast.warn('Preencha uma Metodologia!');
      return;
    }

    if (data.id > 0)
      etapaActions
        .update(data.id, data)
        .then((result: IEtapa) => {
          const sub = etapas.filter((f) => f.id !== result.id);
          setEtapas(sub.concat(result));
          atualizarFormulario(empty);
          toast.success('Atualizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Atualizar!');
        });
    else
      etapaActions
        .create(data)
        .then((result: IEtapa) => {
          setEtapas(etapas.concat(result));
          atualizarFormulario(empty);
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });

    setLoadingSave(false);
  };

  useEffect(() => {
    etapaActions
      .list()
      .then((resp: IEtapa[]) => {
        setEtapas(resp);
      })
      .catch((err: any) => toast.warn(err));

    setLoading(false);

    metodologiaActions
      .list()
      .then((resp: IMetodologia[]) => setMetodologias(resp))
      .catch((err: any) => toast.warn(err));
  }, []);

  const atualizarFormulario = (result: any) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const deleteValue = () => {
    etapaActions
      .delete(etapasTemp.id!)
      .then((resp: IEtapa[]) => {
        const sub = etapas.filter((f) => f.id !== etapasTemp.id);
        setEtapas(sub);
        atualizarFormulario(empty);
      })
      .catch((err: any) => toast.warn(err));

    methods.reset(etapasTemp, { keepDefaultValues: true });
    setIdAtualizar(etapasTemp.id);
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setEtapasTemp(obj);
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
    setEtapasTemp({});
  };

  return (
    <BasePage>
      <Menu />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse item ?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato id="formArea" onSubmit={methods.handleSubmit(onSubmit)}>
                <h3>{idAtualizar ? `Atualizar - ID: ${idAtualizar}` : 'Novo Cadastro'}</h3>
                <Controller
                  control={methods.control}
                  name="nome"
                  render={({ field: { ref, ...field } }) => (
                    <FormControl required fullWidth variant="outlined">
                      <FormInput {...field} inputRef={ref} value={field.value} label="Nome" />
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name="idMetodologia"
                  render={({ field }) => (
                    <FormControl fullWidth sx={{ marginTop: '20px' }}>
                      <InputLabel>Metodologia</InputLabel>
                      <SelectStato {...field} label="Metodologia">
                        {metodologias.map((metodologia: IMetodologia, index: number) => (
                          <MenuItem value={metodologia.id} key={index}>
                            {metodologia.nome}
                          </MenuItem>
                        ))}
                      </SelectStato>
                    </FormControl>
                  )}
                />

                <Controller
                  control={methods.control}
                  name="texto"
                  render={({ field: { ref, ...field } }) => (
                    <div style={{ paddingTop: '20px' }}>
                      <EditorHtml
                        setAsHtml={field.onChange}
                        initialHtml={field.value}
                        label={'Texto'}
                      />
                    </div>
                  )}
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Controller
                    control={methods.control}
                    name="ativo"
                    render={({ field }) => (
                      <FormControlLabelStato
                        control={
                          <Checkbox
                            {...field}
                            checked={field.value}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        }
                        label="Ativo"
                      />
                    )}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '10px',
                  }}
                >
                  <ButtonStato type="submit" variant="contained">
                    Salvar
                  </ButtonStato>
                  {idAtualizar ? (
                    <ButtonStato
                      color="error"
                      style={{ marginLeft: '25px' }}
                      onClick={() => atualizarFormulario(empty)}
                      variant="contained"
                    >
                      Cancelar
                    </ButtonStato>
                  ) : (
                    <></>
                  )}
                </div>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={etapas}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

const FormInput = styled(TextFieldStato)`
  margin-top: 20px !important;
`;

const FormControlLabelStato = styled(FormControlLabel)`
  margin-top: 20px !important;
`;

export default Etapa;
