import { styled } from 'styled-components';
import Menu from '../../../../components/FerramentasAdministrativas/Menu';
import {
  Box,
  Checkbox,
  Grid,
  CircularProgress,
  Typography,
  Step,
  StepLabel,
  Stepper,
  Icon,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { IArea, IGrupo, IUsuario } from '../../../../commons/genericTypes';
import { DataGrid, GridEventListener, GridCellParams, GridColDef, ptBR } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ButtonStato, GridContainer } from '../../../../commons/styleds';
import { useUsuarioActions } from '../../../../states/config/CadastroUsuarios/usuarios/usuario.actions';
import { useGrupoActions } from '../../../../states/grupos/grupo.actions';
import { useAreaActions } from '../../../../states/areas/area.actions';
import React from 'react';
import UsuarioStepper1 from './Steps/stepper1';
import UsuarioStepper2 from './Steps/stepper2';
import UsuarioStepper3 from './Steps/stepper3';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../../states/config/CadastroUsuarios/usuarios/AuthState';
import { history } from '../../../../helpers/history';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const empty: IUsuario = {
  id: 0,
  nome: '',
  email: '',
  senha: '',
  ativo: true,
  modeloComercial: '',
  tipoConsultorB2C: false,
  tipoConsultorB2B: false,
  celular: '',
  cargo: '',
  permissoes: [],
  administrador: false,
};

const steps = ['', '', ''];

const Usuario: React.FC = () => {
  const methods = useForm({ defaultValues: empty });
  const auth = useRecoilValue(authAtom);
  const usuarioActions = useUsuarioActions();
  const [grupos, setGrupos] = useState<IGrupo[]>([]);
  const grupoActions = useGrupoActions();
  const [areas, setAreas] = useState<IArea[]>([]);
  const areaActions = useAreaActions();
  const [novoUsuario, setNovoUsuario] = useState<boolean>(true);
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [idAtualizar, setIdAtualizar] = useState<number | undefined>(undefined);
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [usuarioTemp, setUsuarioTemp] = useState<IUsuario>(empty);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'Nome',
      width: 165,
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 220,
      editable: true,
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      width: 140,
      editable: true,
    },
    {
      field: 'celular',
      headerName: 'Celular',
      width: 140,
      editable: true,
    },
    {
      field: 'cargo',
      headerName: 'Cargo',
      width: 130,
      editable: true,
    },
    {
      field: 'action',
      headerName: ' ',
      width: 100,
      editable: true,
      renderCell: (params: GridCellParams) => {
        let obj = params.row as IUsuario;
        return (
          <Icon onClick={() => handleClickOpen(obj)} style={{ color: 'gray', cursor: 'pointer' }}>
            delete
          </Icon>
        );
      },
    },
  ];

  const handleClickOpen = (obj: any) => {
    setOpen(true);
    setLoadingForm(true);
    setUsuarioTemp(obj);
  };

  const deleteValue = () => {
    usuarioActions
      .deletarUsuario(usuarioTemp.id)
      .then((resp: IUsuario[]) => {
        const sub = usuarios.filter((f) => f.id !== usuarioTemp.id);
        setUsuarios(sub);
        atualizarFormulario(empty);
      })
      .catch((err: any) => toast.warn(err));

    methods.reset(usuarioTemp, { keepDefaultValues: true });
    setIdAtualizar(usuarioTemp.id);
    setLoadingForm(true);
    setOpen(false);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params: any) => {
    atualizarFormulario(params.row);
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = async (data: IUsuario) => {
    if (activeStep < 3) return;

    if (
      data.tipoConsultorB2C &&
      (data.valorHoraB2C == undefined || data.valorHoraB2C.toString() == '')
    ) {
      setActiveStep(1);
      toast.error('Preenchar um valor hora B2B para esse consultor !');
      return;
    }

    if (
      data.tipoConsultorB2B &&
      (data.valorHoraB2B == undefined || data.valorHoraB2B.toString() == '')
    ) {
      setActiveStep(1);
      toast.error('Preenchar um valor hora B2B para esse consultor !');
      return;
    }

    if (data.senha !== data.confirmarSenha && idAtualizar === 0) {
      setActiveStep(0);
      toast.error('Senha e Confirma Senha estão diferentes !');
      return;
    }

    const consultor =
      grupos.find((f) => f.id === data.idGrupo)?.nome.toLowerCase() === 'consultores';
    if (consultor) data.consultor = true;

    if (data.id > 0) AtualizarUsuario(data);
    else
      usuarioActions
        .criarUsuario(data)
        .then((result: any) => {
          setUsuarios(usuarios.concat(result));
          atualizarFormulario(empty);
          setActiveStep(0);
          toast.success('Cadastro realizado com sucesso');
        })
        .catch(() => {
          toast.error('Erro ao Cadastrar!');
        });
  };

  const carregarCombos = async () => {
    grupoActions.list().then((result: IGrupo[]) => {
      setGrupos(result.filter((g) => g.nome !== 'Consultores'));
    });

    areaActions.get().then((result: IArea[]) => {
      setAreas(result);
    });
  };

  const AtualizarUsuario = (data: any) => {
    usuarioActions
      .salvarUsuario(data)
      .then((result: any) => {
        const sub = usuarios.filter((f) => f.id !== result.resultado.id);
        setUsuarios(sub.concat(result.resultado));
        atualizarFormulario(empty);
        setActiveStep(0);
        toast.success('Atualizado com sucesso');
      })
      .catch(() => {
        toast.error('Erro ao Atualizar!');
      });
  };

  const cancelar = () => {
    setActiveStep(0);
    atualizarFormulario(empty);
  };

  useEffect(() => {
    // if (!possuiPermissao(auth, ['cadastro de usuarios'])) {
    // toast.warn('Você não possui permissão para acessar esse módulo');
    // history.push('/');
    // }

    atualizarFormulario(empty);
    setIdAtualizar(0);
    carregarCombos();
    usuarioActions
      .buscarUsuarios()
      .then((resp: any) => setUsuarios(resp.resultado))
      .catch((err: any) => toast.warn(err));
    setLoading(false);
  }, []);

  const atualizarFormulario = (result: IUsuario) => {
    methods.reset(result, { keepDefaultValues: true });
    setIdAtualizar(result.id);
    setActiveStep(0);
    setNovoUsuario(result.id == 0);
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
    }, 1500);
  };

  const getStep = () => {
    switch (activeStep) {
      case 0:
        return <UsuarioStepper1 novoUsuario={novoUsuario} />;
      case 1:
        return <UsuarioStepper2 grupos={grupos} areas={areas} />;
      case 2:
        return <UsuarioStepper3 />;
      default:
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
    setLoadingForm(false);
    setUsuarioTemp(empty);
  };

  return (
    <BasePage>
      <Menu />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Tem certeza que dejesa excluir esse usuário?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={deleteValue}>Sim</Button>
          <Button variant="contained" color="error" onClick={handleClose} autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      <Container container>
        <Grid item xs={12} md={4}>
          {!loadingForm ? (
            <FormProvider {...methods}>
              <FormStato id="formArea" onSubmit={methods.handleSubmit(onSubmit)}>
                <h3>{idAtualizar ? `Atualizar Usuario - ID: ${idAtualizar}` : 'Novo Usuario'}</h3>

                <Stepper activeStep={activeStep} sx={{ marginTop: '5px' }}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <React.Fragment>
                  {getStep()}
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <ButtonStato
                      color="inherit"
                      variant="contained"
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      sx={{ mr: 1 }}
                    >
                      Voltar
                    </ButtonStato>
                    <Box sx={{ flex: '1 1 auto' }} />
                    {idAtualizar! > 0 ? (
                      <ButtonStato
                        color="error"
                        variant="contained"
                        sx={{ marginRight: '10px' }}
                        onClick={cancelar}
                      >
                        {'Cancelar'}
                      </ButtonStato>
                    ) : (
                      <></>
                    )}
                    {activeStep >= 2 ? (
                      <ButtonStato type="submit" onClick={handleNext} variant="contained">
                        {'Salvar'}
                      </ButtonStato>
                    ) : (
                      <ButtonStato variant="contained" onClick={handleNext}>
                        {'Próximo'}
                      </ButtonStato>
                    )}
                  </Box>
                </React.Fragment>
              </FormStato>
            </FormProvider>
          ) : (
            <>
              <CircularProgress
                style={{
                  width: '60px',
                  height: '60px',
                  position: 'relative',
                  left: '50%',
                  top: '25%',
                }}
              />
            </>
          )}
        </Grid>

        <Grid item md={0.5}></Grid>

        <Grid item xs={12} md={7.2}>
          <Box
            sx={{
              height: '60vh',
              width: '100%',
            }}
          >
            <ThemeProvider theme={createTheme({}, ptBR)}>
              <DataGrid
                getRowId={(row) => row?.id}
                onRowClick={handleRowClick}
                rows={usuarios ?? []}
                columns={columns}
                loading={loading}
                sx={{
                  backgroundColor: 'white',
                  border: '1px solid black',
                  borderRadius: '20px',
                }}
              />
            </ThemeProvider>
          </Box>
        </Grid>
      </Container>
    </BasePage>
  );
};

const GridItemCadastro = styled(Grid)`
  padding-bottom: 20px;
`;

export const GridItemCadastroBtnSalvar = styled(GridItemCadastro)`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

const BasePage = styled.div`
  padding: 15px;
`;

const Container = styled(GridContainer)`
  padding-top: 50px;
  padding-left: 20px;
`;

const FormStato = styled.form`
  border: 1px solid black;
  border-radius: 19px;
  padding: 20px;
`;

export default Usuario;
